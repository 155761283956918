import { CSRF_TOKEN } from "../constants.js";
import fetchRetry from "fetch-retry";
const fetchWithRetry = fetchRetry(fetch);

export default ({ messagesUrl, userId, id }) => ({
  loading: false,
  messages: [],
  nextUrl: messagesUrl,
  hasMore: false,
  showBackToBottom: false,
  userId,

  init() {
    Echo.private(`App.Models.Conversation.${id}`)
      .listen("Conversations\\MessageDelivered", async (event) => {
        this.upsertMessage(event);

        if (event.sender.id === this.userId) {
          return;
        }

        fetch(`/inbox/${id}/participants`, {
          method: "PUT",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            "X-CSRF-TOKEN": CSRF_TOKEN,
            "X-Socket-ID": Echo.socketId(),
          },
        }).catch((error) => console.error(error));
      })

      .listen("Conversations\\ConversationSeen", async (event) => {
        const lastMessagesResponse = await fetchWithRetry(
          `/inbox/${id}/messages`,
          {
            method: "GET",
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              "X-Socket-ID": Echo.socketId(),
            },
          },
        ).catch(() => null);

        if (!lastMessagesResponse || !lastMessagesResponse.ok) {
          return;
        }

        try {
          const json = await lastMessagesResponse.json();
          const updatedMessages = json.data;

          updatedMessages.forEach((message) => {
            this.upsertMessage(message);
          });
        } catch (jsonError) {
          console.error(
            `Failed to parse JSON for conversation ${id} with user ${userId}. Response:`,
            JSON.stringify(lastMessagesResponse),
          );
        }
      });
  },

  upsertMessage(message) {
    const index = this.messages.findIndex((m) => m.id === message.id);

    if (index === -1) {
      this.messages.push(message);
    } else if (this.messages[index] !== message) {
      this.messages[index] = message;
    }
  },

  async loadMoreMessages() {
    if (!this.nextUrl) {
      return;
    }

    this.loading = true;

    try {
      const response = await fetchWithRetry(this.nextUrl, {
        method: "GET",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-Socket-ID": Echo.socketId(),
        },
      });

      if (!response.ok) {
        alert("An error has occurred, please refresh and try again.");
        return;
      }

      const json = await response.json();

      this.nextUrl = json.links.next;

      this.hasMore = !!this.nextUrl;

      // Add messages to the beginning of the array and update duplicates if any
      this.messages.unshift(
        ...json.data.reverse().filter((item) => {
          const index = this.messages.findIndex(
            (message) => message.id === item.id,
          );
          if (index !== -1) {
            this.messages[index] = item;
            return false;
          }
          return true;
        }),
      );
    } catch (error) {
      alert(
        `Something has gone wrong, please try again later or contact support.`,
      );
    } finally {
      this.loading = false;
    }
  },
});
